::-ms-reveal {
  display: none;
}

input:-webkit-autofill::first-line {
  font-family: 'Open Sans', sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #F8F4F4;

  --primaryColor: #1C68FF;
  --secondaryColor: #1CA5FF;
  --primaryHover: #1C99FF;
  --backgroundColor: white;
  --primaryText: #212121;
  --secondaryText: #6A6A6A;
  --text: #ffffff;
  --dividerColor: #CED4DA ;
  -webkit-font-smoothing: antialiased;
  
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #f8f9fa;
}

.dropdown-item:hover {
  background-color: rgba(28, 104, 255, 0.1);
}

div.card:hover {
  background-color: #f9f9f9 !important;
}

.btn-shadow {
  box-shadow: 1px 3px 20px #1C68FF96!important;
}

.btn-text {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 3.2px;
}

.icon-flipped {
  transform: scaleX(-1) !important;
  -moz-transform: scaleX(-1) !important;
  -webkit-transform: scaleX(-1) !important;
  -ms-transform: scaleX(-1) !important;
}

.iframe-no-border {
  border: none
}

.modal-content-full {
  height: 98%;
  max-height: 800px;
}

input.customPlaceholder::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ACACAC;
  opacity: 1; /* Firefox */
}

input.customPlaceholder::-ms-input-placeholder { /* Microsoft Edge */
  color: #ACACAC;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,.75);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255,255,255,1);
}

.modal-content  {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}

/*::-webkit-scrollbar {*/
/*  width: 6px !important;*/
/*  height: 6px !important;*/
/*}*/

/*::-webkit-scrollbar-track {*/
/*  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
/*}*/

/*::-webkit-scrollbar-thumb {*/
/*  background-color: darkgrey;*/
/*  outline: 1px solid slategrey;*/
/*}*/

h1 {
  font-size: 96px;
}

h2 {
  font-size: 60px;
}

h3 {
  font-size: 48px;
}

h4 {
  font-size: 34px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
  font-weight: 600;
}
